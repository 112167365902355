import { logEvent } from "firebase/analytics";
import React, { useState, useEffect } from "react";
import { memo } from "react";

import { GridSlide } from "../components/GirdSlide";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { analytics } from "../firebase";

import { Parapara } from "./Parapara";
import { TutorialOverlay } from "./TutorialOverlay";

interface Props {
  images: string[] | null;
  isLoading: boolean;
  onModeChange?: (mode: string) => void;
}

export const BaseViewer = memo(({ images, isLoading, onModeChange }: Props) => {
  const [displayMode, setDisplayMode] = useState("Parapara");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setIsOpen(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMode = e.target.value;
    setDisplayMode(newMode);
    if (onModeChange) {
      onModeChange(newMode);
    }
    logEvent(
      analytics,
      `パラパラとスライド選択${
        window.location.pathname + window.location.search
      }`,
    );
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  return (
    <>
      <Space height={16} />
      <div className="flex justify-end">
        <select
          onChange={handleChange}
          value={displayMode}
          className={`${
            displayMode === "Parapara" ? "bg-[#112b68]" : "bg-[#5d6d91]"
          }`}
          style={{
            position: "fixed",
            zIndex: 999,
            width: "136px",
            height: "36px",
            border: "1px solid black",
            borderRight: "4px solid black",
            borderBottom: "4px solid black",
            borderRadius: "8px",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 700,
            textAlign: "center",
            transition: "box-shadow 0.3s ease",
            cursor: "pointer",
            outline: "none",
            backgroundColor: displayMode === "Parapara" ? "#112b68" : "#5d6d91",
            padding: "0 40px",
          }}
        >
          <option value="Parapara">パラパラ</option>
          <option value="Slide">スライド</option>
        </select>
      </div>
      <>
        <Space height={48} />
        {displayMode === "Parapara" ? (
          images && <Parapara images={images} />
        ) : (
          <GridSlide images={images} />
        )}
        <Space height={48} />
      </>
      {isOpen && (
        <TutorialOverlay isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
});
