import {
  ProductType,
  GengaInfoType,
  ApplyGengaInfoType,
  ApplyShipInfoType,
} from "@gengakuji/common";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";

import { AddressForm } from "../components/AddressForm";
import { ApplyItem } from "../components/ApplyItem";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { DisabledButton } from "../components/Button/DisabledButton";
import { Fukidashi } from "../components/Fukidashi";
import { H2 } from "../components/H2";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { GengaModal } from "../components/Modal/GengaModal";
import { Popup } from "../components/Popup";
import { Space } from "../components/Space";
import { WChanceTable } from "../components/Table/WChanceTable";
import {
  getGengaInfo,
  getProduct,
  getApplyGengaInfo,
  getApplyShipInfo,
  applyWChance,
  getTotalDrawCount,
} from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { formatDate } from "../utils/index";

import NotFound from "./NotFound";

const WChance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [validProductId, setValidProductId] = useState<boolean>(true);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [gengaInfo, setGengaInfo] = useState<GengaInfoType[] | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [ticketCounts, setTicketCounts] = useState<{ [key: number]: number }>(
    {},
  );
  const [restTicketCount, setRestTicketCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedGengaInfo, setSelectedGengaInfo] =
    useState<GengaInfoType | null>(null);
  const [applyGengaInfo, setApplyGengaInfo] = useState<
    ApplyGengaInfoType[] | null
  >(null);
  const [shipInfo, setShipInfo] = useState<ApplyShipInfoType | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { productId } = useParams();
  const user = useAuthUser();

  //住所登録関連
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sei, setSei] = useState("");
  const [mei, setMei] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [building, setBuilding] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [seiError, setSeiError] = useState("");
  const [meiError, setMeiError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [zipError, setZipError] = useState("");
  const navigate = useNavigate();
  const now = new Date();

  const start = productData ? new Date(productData.start.toDate()) : now;
  const end = productData
    ? new Date(productData.wchance.applyDeadline.toDate())
    : now;
  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);
  const isEnd = now < end;

  //バリデーションまとめて判定
  const isFormValid =
    firstName &&
    !firstNameError &&
    lastName &&
    !lastNameError &&
    sei &&
    !seiError &&
    mei &&
    !meiError &&
    phone &&
    !phoneError &&
    zip &&
    !zipError &&
    address;

  //商品情報、原画情報、過去の応募情報の取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // 商品情報の取得
        if (!productId) {
          setValidProductId(false);
          setIsLoading(false);
          return;
        }
        const productData = await getProduct(productId);
        if (!productData) {
          setValidProductId(false);
          setIsLoading(false);
          return;
        }
        setProductData(productData);

        // 情報解禁判定
        const open = productData.open.toDate();
        setIsOpen(now > open);

        // 原画情報の取得
        const gengaInfo = await getGengaInfo(productId);
        setGengaInfo(gengaInfo);

        // ユーザーの過去の応募情報を取得
        if (user) {
          const applyGengaInfo = await getApplyGengaInfo(productId, user.uid);
          setApplyGengaInfo(applyGengaInfo);

          //くじ引いた合計回数
          const totalDrawCount = await getTotalDrawCount(productId, user.uid);
          const totalApplyCount = calculateTotalApplyCount(applyGengaInfo);
          //残りの応募可能チケット枚数
          setRestTicketCount(totalDrawCount - totalApplyCount);

          const shipInfo = await getApplyShipInfo(productId, user.uid);
          setShipInfo(shipInfo);
          //shipInfoがあれば住所情報をセット
          if (shipInfo) {
            setFirstName(shipInfo.firstName);
            setLastName(shipInfo.lastName);
            setSei(shipInfo.sei);
            setMei(shipInfo.mei);
            setPhone(shipInfo.phone.toString());
            setZip(shipInfo.zip.toString());
            setPrefecture(shipInfo.prefecture);
            setCity(shipInfo.city);
            setAddress(shipInfo.address);
            setBuilding(shipInfo.building);
          }
        } else {
          setRestTicketCount(0);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setValidProductId(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [productId, user]);

  // 各原画に応募した回数の合計を計算する関数
  const calculateTotalApplyCount = (
    applyGengaInfo: ApplyGengaInfoType[],
  ): number => {
    return applyGengaInfo.reduce(
      (total, genga) =>
        total +
        genga.items.reduce((sum, item) => sum + (item.applyCount || 0), 0),
      0,
    );
  };

  //原画ごとの応募枚数の初期値を設定
  useEffect(() => {
    if (gengaInfo && applyGengaInfo) {
      const initialTicketCounts = gengaInfo.reduce<{ [key: string]: number }>(
        (acc, genga) => {
          // applyGengaInfoから対応するgengaのapplyCountを見つける
          const applyCount =
            applyGengaInfo
              .find((apply) => apply.productId === genga.productId)
              ?.items.find((item) => item.index === genga.index)?.applyCount ||
            0;

          // applyCountが見つかればそれを、見つからなければ0を初期値とする
          acc[genga.index] = applyCount;
          return acc;
        },
        {},
      );
      setTicketCounts(initialTicketCounts);
    } else if (gengaInfo) {
      // applyGengaInfoがない場合は全てのカウントを0に設定する
      const initialTicketCounts = gengaInfo.reduce<{ [key: string]: number }>(
        (acc, genga) => {
          acc[genga.index] = 0;
          return acc;
        },
        {},
      );
      setTicketCounts(initialTicketCounts);
    }
  }, [gengaInfo, applyGengaInfo]);

  // 応募する関数
  const handleSubmit = async () => {
    if (!productId || !user || !gengaInfo || !ticketCounts || !isEnd) {
      console.error("失敗しました");
      return;
    }

    setIsBtnLoading(true);

    const shipInfo: ApplyShipInfoType = {
      firstName,
      lastName,
      sei,
      mei,
      phone: phone,
      zip: zip,
      prefecture,
      city,
      address,
      building,
      productId: productId,
    };

    const result = await applyWChance(
      productId,
      user,
      gengaInfo,
      ticketCounts,
      shipInfo,
    );

    if (result) {
      setShowPopup(true);
    } else {
      console.error("応募に失敗しました。");
    }

    setIsBtnLoading(false);
  };

  // チケット枚数を更新する関数
  const updateTicketCount = (
    gengaId: number,
    change: number,
    allChange: number,
  ) => {
    if (change > 0 && restTicketCount > 0) {
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [gengaId]: (prevCounts[gengaId] || 0) + change,
      }));
      setRestTicketCount((prevCount) => prevCount + allChange);
    } else if (
      change < 0 &&
      ticketCounts[gengaId] &&
      ticketCounts[gengaId] > 0
    ) {
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [gengaId]: (prevCounts[gengaId] || 0) + change,
      }));
      setRestTicketCount((prevCount) => prevCount + allChange);
    }
  };

  // 原画モーダルを開く関数
  const openGengaModal = (index: GengaInfoType) => {
    setSelectedGengaInfo(index);
    setShowModal(true);
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!validProductId || !productData?.wchance.isOpen) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>
          {(productData && productData.productName) || ""}Wチャンス応募ページ
        </title>
      </Helmet>
      <Space height={16} />
      {isLoading ? (
        <>
          <Space height={64} />
          <Loading />
          <Space height={64} />
        </>
      ) : (
        <>
          <Img
            imgPath={`${productId}/wchance.png`}
            alt="Wチャンス画像"
            className="border-2 border-white"
          />
          <Space height={16} />
          <h2>
            {(productData && productData.productName) || ""}
            <br />
            Wチャンス応募ページ
          </h2>
          <Space height={16} />
          <Link to={`/${productData?.productId || ""}`}>
            <BaseButton
              title="商品ページへ戻る"
              backgroundColor="#c9e900"
              textColor="#000"
            />
          </Link>
          <Space height={16} />
          <WChanceTable
            productData={productData}
            gengaInfo={gengaInfo}
            formattedStart={formattedStart}
            formattedEnd={formattedEnd}
          />
          <Space height={24} />
          <p>
            くじを引いた回数分、{productData.wchance.prize}
            があたるWチャンスに応募することができます！
            <br />
            応募期間ならいつでも応募内容の編集が可能です。
          </p>
          <BaseWhiteBg>
            <H2
              text={isEnd ? "住所登録" : "登録済み住所"}
              blue={isEnd ? 3 : 4}
            />
            <AddressForm
              //住所登録情報
              firstName={firstName}
              lastName={lastName}
              sei={sei}
              mei={mei}
              phone={phone}
              zip={zip}
              prefecture={prefecture}
              city={city}
              address={address}
              building={building}
              //エラーメッセージ
              firstNameError={isEnd ? firstNameError : undefined}
              lastNameError={isEnd ? lastNameError : undefined}
              seiError={isEnd ? seiError : undefined}
              meiError={isEnd ? meiError : undefined}
              phoneError={isEnd ? phoneError : undefined}
              zipError={isEnd ? zipError : undefined}
              //住所登録情報の更新関数
              setFirstName={setFirstName}
              setLastName={setLastName}
              setSei={setSei}
              setMei={setMei}
              setPhone={setPhone}
              setZip={setZip}
              setPrefecture={setPrefecture}
              setCity={setCity}
              setAddress={setAddress}
              setBuilding={setBuilding}
              //エラーメッセージの更新関数
              setFirstNameError={isEnd ? setFirstNameError : undefined}
              setLastNameError={isEnd ? setLastNameError : undefined}
              setSeiError={isEnd ? setSeiError : undefined}
              setMeiError={isEnd ? setMeiError : undefined}
              setPhoneError={isEnd ? setPhoneError : undefined}
              setZipError={isEnd ? setZipError : undefined}
              isReadOnly={!isEnd}
            />
          </BaseWhiteBg>
          <Space height={24} />
          <BgH2 text="景品一覧" />
          <Space height={24} />
          {gengaInfo &&
            productData &&
            gengaInfo.map((genga) => (
              <ApplyItem
                key={genga.displayName}
                index={genga.index}
                displayName={genga.displayName}
                productId={genga.productId}
                applyCount={ticketCounts[genga.index] || 0}
                restTicketCount={restTicketCount}
                updateTicketCount={(change, allChange) =>
                  updateTicketCount(genga.index, change, allChange)
                }
                onClick={() => openGengaModal(genga)}
                end={productData.wchance.applyDeadline}
              />
            ))}
          <Space height={24} />
          <div className="fixed bottom-4 z-[999] flex w-full max-w-[calc(100vw-32px)] justify-center min-[722px]:max-w-[690px]">
            {!isEnd ? (
              <DisabledButton title="応募期間終了" />
            ) : (
              <>
                {/* 残り枚数がある場合は、残り枚数を表示 */}
                {restTicketCount > 0 && (
                  <Fukidashi>
                    <p>残り{restTicketCount}枚</p>
                  </Fukidashi>
                )}
                <Space height={8} />
                {/* 応募フォームが有効かつ残り枚数が0の場合は、応募ボタンを有効化 */}
                {isFormValid && restTicketCount == 0 ? (
                  <BaseButton
                    title="応募する"
                    backgroundColor="#15c0ed"
                    textColor="#000"
                    onClick={handleSubmit}
                    isLoading={isBtnLoading}
                  />
                ) : (
                  <DisabledButton title="応募する" />
                )}
              </>
            )}
          </div>
        </>
      )}
      <Space height={48} />
      {showModal && selectedGengaInfo && productData && (
        <GengaModal
          gengaInfo={selectedGengaInfo}
          productData={productData}
          onClose={() => setShowModal(false)}
        />
      )}
      <Popup
        isVisible={showPopup}
        onClose={() => setShowPopup(false)}
        message={
          "応募内容が保存されました！\n応募期間はいつでも応募内容を編集することができます。"
        }
      />
    </>
  );
};

export default WChance;
