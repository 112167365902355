import { createContext, useEffect, useState } from "react";

import { auth } from "../firebase";

type Auth =
  | {
      user: {
        uid: string;
        email: string | null;
        loginMethods: string[];
        getIdToken: () => Promise<string>;
      } | null;
      initialized: true;
    }
  | {
      user: null;
      email: null;
      initialized: false;
    };

export const AuthContext = createContext<Auth>({
  user: null,
  email: null,
  initialized: false,
});

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<Auth>({
    user: null,
    email: null,
    initialized: false,
  });

  useEffect(() => {
    auth.onIdTokenChanged((user) => {
      if (!user) {
        setUser({ user: null, initialized: true });
      } else {
        const loginMethods = user.providerData.map(
          (userInfo) => userInfo.providerId,
        );
        setUser({
          user: {
            uid: user.uid,
            email: user.email,
            loginMethods,
            getIdToken: () => user.getIdToken(),
          },
          initialized: true,
        });
      }
    });
  }, []);

  if (user.initialized) {
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
  } else {
    return null;
  }
};
