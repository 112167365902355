import { logEvent } from "firebase/analytics";
import { memo } from "react";

import { analytics } from "../../firebase";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  gaEvent?: string;
  className?: string;
  disabled?: boolean;
}

export const BlackButton = memo(
  ({
    onClick,
    title,
    gaEvent = `${title}${window.location.pathname + window.location.search}`,
    className = "",
    disabled = false,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick && !disabled) {
        onClick(e);
      }

      if (gaEvent && !disabled) {
        logEvent(analytics, gaEvent);
      }
    };

    return (
      <button
        className={`h-10 w-full bg-black p-2 text-sm text-white
          ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
          ${className}`}
        onClick={handleClick}
        disabled={disabled}
      >
        {title}
      </button>
    );
  },
);
