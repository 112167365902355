import { logEvent } from "firebase/analytics";
import { memo } from "react";

import { analytics } from "../../firebase";
import { Loading } from "../Loading";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  width?: string;
  border?: string;
  isLoading?: boolean;
  gaEvent?: string;
}

export const BaseButton = memo(
  ({
    onClick,
    title,
    backgroundColor = "#ff4127",
    textColor = "#fff",
    width = "100%",
    border,
    isLoading = false,
    gaEvent = `${title}${window.location.pathname + window.location.search}`,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (gaEvent) {
        logEvent(analytics, gaEvent);
      }
    };

    return (
      <button
        className={`h-[52px] max-w-[320px] cursor-pointer rounded-full border border-b-[6px] border-black text-center text-sm font-bold ${
          isLoading ? "cursor-not-allowed" : ""
        }`}
        onClick={handleClick}
        style={{ backgroundColor, color: textColor, width, border }}
        disabled={isLoading}
      >
        {isLoading ? <Loading /> : title}
      </button>
    );
  },
);
