export const handlePasswordChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setPassword: (value: string) => void,
  setPasswordError: (error: string) => void,
) => {
  const password = e.target.value;
  setPassword(password);

  if (password.length < 6) {
    setPasswordError("6文字以上にする必要があります。");
  } else {
    setPasswordError("");
  }
};
