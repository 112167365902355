import { memo, useCallback, useState } from "react";

import { SlideModal } from "./Modal/SlideModal";
import { Space } from "./Space";

interface Props {
  images: string[] | null;
}

export const GridSlide = memo(({ images }: Props) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null,
  );

  //原画画像クリックしたらその画像をモーダルで表示
  const handleImageClick = useCallback((index: number) => {
    setSelectedImageIndex(index);
  }, []);

  return (
    <>
      <Space height={8} />
      <div className="grid grid-cols-2 gap-4">
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              onClick={() => handleImageClick(index)}
              className="cursor-pointer"
            >
              <img src={image} alt={`${index}`} className="w-full" />
            </div>
          ))}
      </div>
      {selectedImageIndex !== null && images && (
        <SlideModal
          imageCount={images.length}
          selectedIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(null)}
          images={images}
        />
      )}
    </>
  );
});
