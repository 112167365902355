import { GengaInfoType } from "@gengakuji/common";
import { collection, getDocs, query } from "firebase/firestore";

import { db } from "../firebase";

// 全ての原画情報を取得し、indexに基づいてソート
export const getGengaInfo: (
  productId: string,
) => Promise<GengaInfoType[]> = async (productId: string) => {
  const gengasCollectionRef = collection(db, "genga_info", productId, "gengas");
  const q = query(gengasCollectionRef);
  const querySnapshot = await getDocs(q);
  const gengaInfoDocs: GengaInfoType[] = [];

  querySnapshot.forEach((doc) => {
    gengaInfoDocs.push(doc.data() as GengaInfoType);
  });

  // indexに基づいてソートする
  return gengaInfoDocs.sort((a, b) => a.index - b.index);
};
