import { VideoSRC } from "@gengakuji/common";
import { memo, useEffect } from "react";

interface Props {
  canPlay: boolean;
  isOpen?: boolean;
  onClose: () => void;
  hasSpecial: boolean;
  videoSrc: VideoSRC;
}

export const ResultOverlay = memo(
  ({ canPlay, onClose, hasSpecial, videoSrc }: Props) => {
    const handleVideoEnd = () => {
      onClose();
    };

    useEffect(() => {
      const videoElement = document.getElementById("video") as HTMLVideoElement;
      if (canPlay) {
        videoElement.play();
      }
    }, [canPlay]);

    return (
      <div className="fixed left-0 top-0 z-[9999] size-full bg-black">
        <video
          id="video"
          muted={true}
          src={hasSpecial ? videoSrc.S : videoSrc.Normal}
          onEnded={handleVideoEnd}
          controls={false}
          className="size-full"
          playsInline
          autoPlay
        />
        <button
          className="absolute bottom-4 left-1/2 h-[52px] max-w-[320px] -translate-x-1/2 cursor-pointer border-none bg-transparent text-center text-xs font-medium text-white opacity-50"
          onClick={onClose}
        >
          スキップ
        </button>
      </div>
    );
  },
);
