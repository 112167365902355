import {
  ProductType,
  ResultType,
  VideoSRC,
  ResultCutInfoType,
} from "@gengakuji/common";
import { logEvent } from "firebase/analytics";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import new_icon from "../assets/icon/new.webp";
import s_icon from "../assets/special/s_icon.webp";
import { BgH2 } from "../components/BgH2";
import { Border } from "../components/Border";
import { BaseButton } from "../components/Button/BaseButton";
import { YubiButton } from "../components/Button/YubiButton";
import { Img } from "../components/Img";
import { CutModal } from "../components/Modal/CutModal";
import { ResultOverlay } from "../components/ResultOverlay";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { analytics } from "../firebase";
import { kujiResultListener, getProduct } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { buyKuji, getCountClassName } from "../utils/index";

const title = "くじ結果";

type ImageBrightnessType = {
  [key: number]: boolean;
};

const Result = memo(() => {
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [isLoadingTen, setIsLoadingTen] = useState(false);
  const [eventLogged, setEventLogged] = useState(false);

  const [imageBrightness, setImageBrightness] = useState<ImageBrightnessType>(
    {},
  );
  const [count, setCount] = useState(1);
  const [result, setResult] = useState<ResultType | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [hasSpecial, setHasSpecial] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<VideoSRC>({ S: "", Normal: "" });

  const [showModal, setShowModal] = useState(false);
  const [selectedCutInfo, setSelectedCutInfo] = useState<
    (ResultCutInfoType & { arrayIndex: number }) | null
  >(null);
  const [productData, setProductData] = useState<ProductType | null>(null);

  // 全て開封済みかどうかを表すフラグ
  const [isAllOpened, setIsAllOpened] = useState(false);

  const [newIconIndexForCutId, setNewIconIndexForCutId] = useState<{
    [cutId: string]: number;
  }>({});

  const user = useAuthUser();

  useEffect(() => {
    const fetchStripeSession = async () => {
      if (!user) return;
      const currentURL = new URL(window.location.href);
      const sessionId = currentURL.searchParams.get("session_id");
      const countParam = currentURL.searchParams.get("count");
      if (countParam) {
        setCount(Number(countParam));
      }
      if (!sessionId) {
        if (canPlay === true) return;
        window.location.href = window.location.origin;
        return;
      }
      const unsub = kujiResultListener(user.uid, sessionId, async (data) => {
        if (data?.numOfSpecial > 0) {
          setHasSpecial(true);
        }

        if (videoSrc.S === "") {
          const productData = await getProduct(data.productId);
          setProductData(productData);

          // productDataが存在し、かつkujiVideoの値がある場合はそれを使用し、
          // そうでない場合はデフォルトの動画パスをセットします。
          const specialVideo =
            productData?.kujiVideo?.S ||
            require("../assets/kujiVideo/special.mp4");
          const normalVideo =
            productData?.kujiVideo?.Normal ||
            require("../assets/kujiVideo/normal.mp4");

          setVideoSrc({
            S: specialVideo,
            Normal: normalVideo,
          });

          setCanPlay(true);
        }
        setResult(data);

        // イベントがログされていない場合のみ実行
        if (data && !eventLogged) {
          logEvent(analytics, "purchase", data.analyticsPayload);
          setEventLogged(true);
        }

        //URLからパラメータの削除
        currentURL.searchParams.delete("session_id");
        currentURL.searchParams.delete("count");
        window.history.replaceState({}, document.title, currentURL.href);
      });
      return unsub;
    };
    fetchStripeSession();
  }, [user, canPlay, eventLogged, videoSrc.S, videoSrc.Normal]);

  //もう一度くじを引く
  const handleRetry = async (drawNumber: number) => {
    if (drawNumber === 1) {
      setIsLoadingOne(true);
    } else {
      setIsLoadingTen(true);
    }

    if (!result?.productId) return;
    const productData = await getProduct(result.productId);
    if (!productData) return;
    await buyKuji(drawNumber, false, productData);

    if (drawNumber === 1) {
      setIsLoadingOne(false);
    } else {
      setIsLoadingTen(false);
    }
  };

  // カットモーダルを開く処理
  const openCutModal = (index: number, cut: ResultCutInfoType) => {
    setImageBrightness((prev) => {
      const updated = { ...prev, [index]: true };
      // 全て開封されていればフラグを立てる
      if (result?.result && result.result.every((_, i) => updated[i])) {
        setIsAllOpened(true);
      }
      return updated;
    });
    setSelectedCutInfo({ ...cut, arrayIndex: index });
    setShowModal(true);

    // 新規アイコンのインデックスを記録
    if (cut.isFirstTime && newIconIndexForCutId[cut.cutId] === undefined) {
      setNewIconIndexForCutId((prev) => ({ ...prev, [cut.cutId]: index }));
    }
  };

  // 全て開封ボタンを押したときの処理
  const handleHideAllCutBukuro = () => {
    if (!result?.result) return;
    const newImageBrightness: ImageBrightnessType = {};
    const newNewIconIndexForCutId = { ...newIconIndexForCutId };

    for (let i = 0; i < result.result.length; i++) {
      const cut = result.result[i];
      newImageBrightness[i] = true;
      if (cut.isFirstTime && newNewIconIndexForCutId[cut.cutId] === undefined) {
        newNewIconIndexForCutId[cut.cutId] = i;
      }
    }
    setImageBrightness(newImageBrightness);
    setNewIconIndexForCutId(newNewIconIndexForCutId);
    setIsAllOpened(true);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Space height={24} />
      <BgH2 text={title} />
      <Space height={24} />
      <div className={getCountClassName(Number(count))}>
        {result?.result &&
          result?.result.map((cut, index) => (
            <div key={index} onClick={() => openCutModal(index, cut)}>
              <div className="relative inline-block cursor-pointer">
                {cut.isFirstTime &&
                  newIconIndexForCutId[cut.cutId] === index && (
                    <img
                      src={new_icon}
                      alt="新規アイコン"
                      className="absolute left-0 top-0 z-[101] w-1/4"
                    />
                  )}
                {cut.type === "S" && (
                  <img
                    src={s_icon}
                    alt="S賞アイコン"
                    className="absolute right-0 top-0 z-[102] w-1/4"
                  />
                )}
                <Img
                  imgPath={`${productData?.productId}/cut_bukuro.webp`}
                  alt="カット袋"
                  className={`absolute left-0 top-0 z-[110] w-full ${
                    !imageBrightness[index] ? "" : "hidden"
                  }`}
                />
                <Img
                  imgPath={`${productData?.productId}/cover/${cut.index}.png`}
                  alt="原画画像"
                  className="w-full shadow-md"
                />
              </div>
              <Space height={16} />
            </div>
          ))}
      </div>
      <Space height={40} />

      {/* 全て開封ボタン表示条件を改善: 各カット個別開封で全開封状態になったら非表示にする */}
      {count > 1 && !isAllOpened && (
        <>
          <BaseButton onClick={handleHideAllCutBukuro} title="全て開封" />
          <Space height={40} />
        </>
      )}

      <Border />
      <Space height={40} />
      <>
        <h3>もう一度くじを引く</h3>
        <Space height={24} />
        <div className="flex w-full justify-center">
          <div className="mr-1 flex-1">
            <BaseButton
              onClick={() => handleRetry(1)}
              title="1回"
              isLoading={isLoadingOne}
            />
          </div>
          <div className="ml-1 flex-1">
            <YubiButton
              onClick={() => handleRetry(10)}
              title="10連"
              isLoading={isLoadingTen}
            />
          </div>
        </div>
      </>
      <Space height={40} />
      <Border />
      <Space height={40} />
      <Link to={`/collection/${productData?.productId || ""}`}>
        <BaseButton
          title="原画をパラパラする"
          backgroundColor="#c9e900"
          textColor="#000"
        />
      </Link>
      <Space height={40} />
      {productData?.wchance.isOpen ? (
        <>
          <Link to={`/${productData?.productId || ""}/wchance`}>
            <BaseButton
              title="Wチャンスに応募する"
              backgroundColor="#15c0ed"
              textColor="#000"
            />
          </Link>
          <Space height={40} />
        </>
      ) : null}
      <Share
        url={`https://gengakuji.com/${productData?.productId || ""}`}
        text={`${productData?.productName || ""}を購入しました！`}
        hashtags={
          productData && productData.hashtags
            ? [...productData.hashtags, "原画くじ"]
            : ["原画くじ"]
        }
      />
      {isOpen && (
        <ResultOverlay
          isOpen={isOpen}
          hasSpecial={hasSpecial}
          canPlay={canPlay}
          onClose={() => setIsOpen(false)}
          videoSrc={videoSrc}
        />
      )}
      <Space height={48} />
      {showModal && selectedCutInfo && (
        <CutModal
          isNew={
            selectedCutInfo.isFirstTime &&
            newIconIndexForCutId[selectedCutInfo.cutId] ===
              selectedCutInfo.arrayIndex
          }
          cutInfo={selectedCutInfo}
          productData={productData}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
});

export default Result;
