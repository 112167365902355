export const handleEmailChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setEmail: (value: string) => void,
  setEmailError: (error: string) => void,
) => {
  setEmail(e.target.value);
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
    setEmailError("無効なメールアドレス形式です。");
  } else {
    setEmailError("");
  }
};
