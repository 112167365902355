import {
  ProductType,
  CutInfoType,
  FirestoreDocument,
  CollectionType,
} from "@gengakuji/common";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";

import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { How } from "../components/How";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { Prize } from "../components/Prize";
import { Share } from "../components/Share";
import { Slide } from "../components/Slide";
import { Space } from "../components/Space";
import { ProductTable } from "../components/Table/ProductTable";
import { useProductId } from "../context/index";
import { getTypedCutInfo, getProduct, getCollection } from "../firebase/index";
import { getUser } from "../firebase/users";
import { useAuthUser } from "../hooks/useAuth";
import { buyKuji, formatDate } from "../utils/index";

import NotFound from "./NotFound";

const Product = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [validProductId, setValidProductId] = useState<boolean>(true);
  const [firstBuy, setFirstBuy] = useState(false);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [collections, setCollections] = useState<
    FirestoreDocument<CollectionType>[]
  >([]);
  const [sCutInfo, setSCutInfo] = useState<CutInfoType[] | null>(null);
  const [normalCutInfo, setNormalCutIndo] = useState<CutInfoType[] | null>(
    null,
  );
  const [isOpen, setIsOpen] = useState(false);

  const user = useAuthUser();
  const { productId } = useParams();
  const { setProductId } = useProductId();

  const now = new Date();
  const start = productData ? new Date(productData.start.toDate()) : now;
  const end = productData ? new Date(productData.end.toDate()) : now;

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  const navigate = useNavigate();

  //商品とカット情報の取得
  useEffect(() => {
    const fetchData = async () => {
      if (productId) {
        const productData = await getProduct(productId);
        setProductId(productId);
        if (!productData) {
          setValidProductId(false);
          setIsLoading(false);
          navigate("/404");
          return;
        } else {
          setProductData(productData);
          const now = new Date();
          const open = new Date(productData.open.toDate());
          setIsOpen(now > open); // 情報解禁判定
        }
      }

      // S賞と通常賞のカット情報の取得
      const sData = productId ? await getTypedCutInfo(productId, "S") : null;
      setSCutInfo(sData);
      const normalData = productId
        ? await getTypedCutInfo(productId, "Normal")
        : null;
      setNormalCutIndo(normalData);

      setIsLoading(false);
    };

    fetchData();
  }, [productId, setProductId]);

  // 初回購入判定
  useEffect(() => {
    const checkFirstBuy = async () => {
      if (user && productId) {
        const userData = await getUser(user.uid);
        if (userData && !userData.boughtProductIds.includes(productId)) {
          setFirstBuy(true);
        }

        const collectionsData = await getCollection(user.uid, productId);
        setCollections(collectionsData);
      }
    };
    checkFirstBuy();
  }, [user, productId]);

  //くじ購入
  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, firstBuy, productData);
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!validProductId) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{(productData && productData.productName) || ""}</title>
      </Helmet>
      <Space height={16} />
      <>
        <Img
          imgPath={`${productId}/ogp.png`}
          alt="商品画像"
          className="border-2 border-white"
        />
        <Space height={16} />
        <h1>「{(productData && productData.anime) || ""}」</h1>
        <h2> {(productData && productData.kujiTitle) || ""}</h2>
        <Space height={16} />
        <Link to="sample">
          <BaseButton
            title="動くデジタル原画のサンプルを見る"
            backgroundColor="#c9e900"
            textColor="#000"
          />
        </Link>
        <Space height={16} />
        {productData?.wchance.isOpen ? (
          <Link to="wchance">
            <BaseButton
              title="Wチャンスに応募する"
              backgroundColor="#15c0ed"
              textColor="#000"
            />
          </Link>
        ) : null}
        <Space height={16} />
        <ProductTable
          productData={productData}
          firstBuy={firstBuy}
          user={user}
          formattedStart={formattedStart}
          formattedEnd={formattedEnd}
        />
        <Space height={40} />
        <Slide productData={productData} productId={productId ?? ""} />
        <Space height={40} />
        <Prize
          title="S賞"
          count={sCutInfo ? sCutInfo.length : 0}
          rate={productData ? productData.rate.SType : 6}
          alt="S賞画像"
          className="mx-auto grid grid-cols-1 gap-4"
          cutInfo={sCutInfo}
          productData={productData}
          collections={collections}
        />
        <Space height={48} />
        <Prize
          title="通常賞"
          count={normalCutInfo ? normalCutInfo.length : 0}
          rate={productData ? productData.rate.NormalType : 94}
          alt="通常賞画像"
          className="mx-auto grid grid-cols-2 gap-4"
          cutInfo={normalCutInfo}
          productData={productData}
          collections={collections}
        />
        <Space height={48} />
        <How />
        <Space height={48} />
        <Share
          url={`https://gengakuji.com/${productData?.productId || ""}`}
          text={
            // 販売前
            now < start
              ? `${
                  productData?.productName ?? ""
                }事前登録受付中！ 【${formattedStart}】より販売開始！`
              : // 販売中
                now <= end
                ? `${productData?.productName ?? ""}販売中！${
                    productData?.initialDiscountRate &&
                    productData.initialDiscountRate > 0
                      ? `【初回限定${productData.initialDiscountRate}%OFF！】`
                      : ""
                  }`
                : // 販売終了
                  `【販売終了】${productData?.productName ?? ""}`
          }
          hashtags={
            productData && productData.hashtags
              ? [...productData.hashtags, "原画くじ"]
              : ["原画くじ"]
          }
        />
        <FixedBottom
          user={user}
          productData={productData}
          start={start}
          end={end}
          firstBuy={firstBuy}
          handleBuyKuji={handleBuyKuji}
        />
      </>
      <Space height={48} />
    </>
  );
});

export default Product;
