import { ProductType } from "@gengakuji/common";
import { ResultType, CutInfoType } from "@gengakuji/common";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import s_icon from "../assets/special/s_icon.webp";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { BgH2 } from "../components/BgH2";
import { Border } from "../components/Border";
import { ColorSpan } from "../components/ColorSpan";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { CutModal } from "../components/Modal/CutModal";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { getKujiResultByReciptNumber, getProduct } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { convertFormattedDate, getCountClassName } from "../utils/index";

interface LocationState {
  result: ResultType;
  product: ProductType;
}

const title = "くじ結果履歴";

const History = memo(() => {
  const state = useLocation().state as LocationState;
  const user = useAuthUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState<ProductType | null>();
  const [resultData, setResultDate] = useState<ResultType | null>();
  const { customReceiptNumber } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedCutInfo, setSelectedCutInfo] = useState<CutInfoType | null>(
    null,
  );

  // 決済ごとのくじ結果履歴を取得
  useEffect(() => {
    const fetchData = async () => {
      if (!state && user && customReceiptNumber) {
        const resultData = await getKujiResultByReciptNumber(
          user.uid,
          customReceiptNumber,
        );
        if (!resultData) {
          navigate("/404");
          return;
        }
        const productData = await getProduct(resultData?.productId);
        if (!productData) {
          navigate("/404");
          return;
        }
        setResultDate(resultData);
        setProductData(productData);
      } else if (state) {
        setProductData(state.product);
        setResultDate(state.result);
      } else {
        navigate("/404");
      }
      setIsLoading(false);
    };
    fetchData();
  }, [state, customReceiptNumber, user, navigate]);

  // カット情報モーダルを開く
  const openCutModal = (cut: CutInfoType) => {
    setSelectedCutInfo(cut);
    setShowModal(true);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        {isLoading || !(productData && resultData) ? (
          <>
            <Space height={64} />
            <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
            <Space height={64} />
          </>
        ) : (
          <>
            <Space height={32} />
            <Img imgPath={`${productData.productId}/ogp.png`} alt="商品画像" />
            <Space height={16} />
            <h3>{productData.productName}</h3>
            <Space height={8} />
            <p>{resultData.count}回</p>
            <Space height={16} />
            <div className="text-left">
              <label>購入日時</label>
              <p>{convertFormattedDate(resultData.boughtAt)}</p>
              <Space height={16} />
              <label>購入金額</label>
              <p>{resultData.analyticsPayload.value}円</p>
              <Space height={16} />
              <label>購入番号</label>
              <p>{resultData.customReceiptNumber}</p>
              <Space height={16} />
            </div>
            <Space height={16} />
            <Border color="#cacaca" />
            <Space height={24} />
            <BgH2 text="くじ結果" />
            <Space height={24} />
            <div className={getCountClassName(Number(resultData.count))}>
              {resultData.result &&
                resultData.result.map((cut, index) => (
                  <div
                    key={cut.displayName + index}
                    onClick={() => openCutModal(cut)}
                  >
                    <div className="relative inline-block cursor-pointer">
                      {cut.type === "S" && (
                        <>
                          <img
                            src={s_icon}
                            alt="S賞アイコン"
                            className="absolute right-0 top-0 z-[101] w-1/4"
                          />
                        </>
                      )}
                      <Img
                        imgPath={`${productData.productId}/cover/${cut.cutId}.png`}
                        alt="原画画像"
                        className="w-full shadow-md"
                      />
                    </div>
                    <Space height={16} />
                    <p>{cut.displayName}</p>
                  </div>
                ))}
            </div>
          </>
        )}
        <Space height={48} />
        <Share
          url={`https://gengakuji.com/${productData?.productId || ""}`}
          text={`${productData?.productName || ""}を購入しました！`}
          hashtags={
            productData && productData.hashtags
              ? [...productData.hashtags, "原画くじ"]
              : ["原画くじ"]
          }
        />
        <Space height={44} />
        <Link to="/mypage/history">
          <ColorSpan>くじ結果一覧へ戻る</ColorSpan>
        </Link>
        <Space height={44} />
      </BaseWhiteBg>
      {showModal && selectedCutInfo && productData && (
        <CutModal
          cutInfo={selectedCutInfo}
          productData={productData}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
});

export default History;
