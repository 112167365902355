import { Timestamp } from "firebase/firestore";
import { ProductType } from "./product";

import { FirestoreDocument } from "./firestore";
import { EmailType } from "./email";

export const SHIP_STATE = {
  BEFORE_SHIP: 0,
  REQUEST_SHIP: 1,
  PACKED: 2,
  SHIPPED: 3,
} as const;

export type shipState = 0 | 1 | 2 | 3;

export type ShipInfoType = {
  productId: string;
  FinalShippingDate: string;
  numOfAvailableShip: number;
  items: { cutId: string; docId: string }[];
  uid: string;
  state: shipState;
  createdAt: Timestamp;
  shippedAt: Timestamp | null;
  customReceiptNumber: string;
  trackingNumber?: string;
};

export type AddressInfoType = {
  uid: string;
  firstName: string;
  lastName: string;
  sei: string;
  mei: string;
  phone: string;
  zip: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;
};

export type RequestedShipInfo = {
  shipInfo: FirestoreDocument<ShipInfoType>;
  productData: ProductType;
  address: AddressInfoType;
  items: OrderType[];
};

export type ShippedInfo = {
  shipInfo: FirestoreDocument<
    Omit<ShipInfoType, "shippedAt"> & { shippedAt: Timestamp }
  >;
  productData: ProductType;
  address: AddressInfoType;
};

export type WinInfoType = {
  productId: string;
  FinalShippingDate: string;
  // numOfAvailableShip: number;
  items: { gengaId: string; displayName: string; index: number }[];
  uid: string;
  state: shipState;
  createdAt: Timestamp;
  shippedAt: Timestamp | null;
  customReceiptNumber: string;
  trackingNumber?: string; //state===2,3のときのみ
};

export type ShippingType = {
  shipInfo: ShipInfoType;
  addressInfo: AddressInfoType;
  email: EmailType;
};

export type OrderType = {
  type: "Normal" | "S";
  name: string;
  amount: number;
  index: number;
  cutId: string;
};

export type TabType = "request" | "requested" | "shipped";
