import { ProductType } from "@gengakuji/common";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";

import { db } from "../firebase";

export type CollectionListType =
  | null
  | {
      collectionCount: number;
      product: {
        productId: string;
        productName: string;
        count: number;
        start: Timestamp;
      };
    }[];

export const getCollectionList = async (
  uid: string,
): Promise<CollectionListType> => {
  // ユーザーデータの取得
  const userDoc = await getDoc(doc(db, "users", uid));
  const userData = userDoc.data();
  if (!userData) {
    console.error("No User: ", uid);
    return null;
  }

  const boughtProductIds = userData.boughtProductIds as string[];
  if (boughtProductIds.length === 0) {
    // 購入済みのプロダクトなし
    return null;
  }

  let collectionList: CollectionListType = [];
  await Promise.all(
    boughtProductIds.map(async (productId) => {
      // プロダクト情報取得
      const productDoc = await getDoc(doc(db, "products", productId));
      const productInfo = productDoc.data() as ProductType;

      // 所持数の取得
      const collectionQuery = collection(db, "collections", uid, productId);
      const collectionSnapshot = await getDocs(collectionQuery);
      const collectionCount = collectionSnapshot.size;

      collectionList!.push({
        collectionCount,
        product: {
          count: productInfo.count,
          productId,
          productName: productInfo.productName,
          start: productInfo.start,
        },
      });
    }),
  );

  collectionList.sort((a, b) => {
    const startCompare =
      b.product.start.toDate().getTime() - a.product.start.toDate().getTime();
    if (startCompare !== 0) {
      return startCompare;
    }
    return a.product.productName.localeCompare(b.product.productName);
  });

  return collectionList;
};
