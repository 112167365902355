import { ProductType } from "@gengakuji/common";

import {
  convertScheduledShippingAt,
  convertshippingRequestDeadline,
} from "../../utils/index";

import { BaseTable } from "./BaseTable";

interface Props {
  productData: ProductType | null;
  firstBuy: boolean;
  user: any;
  formattedStart: string;
  formattedEnd: string;
}

export const ProductTable = ({
  productData,
  firstBuy,
  user,
  formattedStart,
  formattedEnd,
}: Props) => {
  const count = productData?.count ?? 0;
  const initialDiscountRate = productData?.initialDiscountRate ?? 0;
  const price1 = productData?.price1 ?? 0;
  const price10 = productData?.price10 ?? 0;
  const isDuplicate = productData?.isDuplicate ?? false;
  const postage = productData?.postage ?? 0;
  const shippingRequestDeadline = productData?.shippingRequestDeadline;
  const scheduledShippingAt = productData?.scheduledShippingAt;
  const copyright = productData?.copyright ?? "";

  const STypeProducts = productData?.productTypes?.SType ?? [];
  const NormalTypeProducts = productData?.productTypes?.NormalType ?? [];
  const paymentMethods = productData?.payment;

  // 割引価格計算
  const calcDiscountedPrice = (originalPrice: number, discount: number) => {
    return Math.floor((originalPrice * (100 - discount)) / 100);
  };

  return (
    <BaseTable>
      <tr>
        <td className="min-w-[88px] text-sm">商品数</td>
        <td>
          全<span className="text-red font-medium">{count}</span>
          種類
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">商品内容</td>
        <td>
          S賞
          <ul>
            {STypeProducts.length > 0 ? (
              STypeProducts.map((val, index) => (
                <li className="mb-2" key={`s-${index}`}>
                  <span>{val}</span>
                </li>
              ))
            ) : (
              <>
                <li className="mb-2" key="s-0">
                  <span>パラパラ原画集</span>
                </li>
                <li className="mb-2" key="s-1">
                  <span>動くデジタル原画</span>
                </li>
              </>
            )}
          </ul>
          通常賞
          <ul>
            {NormalTypeProducts.length > 0 ? (
              NormalTypeProducts.map((val, index) => (
                <li className="mb-2" key={`n-${index}`}>
                  <span>{val}</span>
                </li>
              ))
            ) : (
              <>
                <li className="mb-2" key="n-0">
                  <span>原画ポストカード</span>
                </li>
                <li className="mb-2" key="n-1">
                  <span>動くデジタル原画</span>
                </li>
              </>
            )}
          </ul>
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">価格（税込）</td>
        <td>
          {(firstBuy || !user) && initialDiscountRate > 0 ? (
            <>
              <span className="text-red font-medium">
                初回限定{initialDiscountRate}%OFF!
              </span>
              <div>
                <span>1回：</span>
                <span className="mr-2 font-medium line-through">
                  {price1}円
                </span>
                <span className="text-red font-medium">
                  {calcDiscountedPrice(price1, initialDiscountRate)}円
                </span>
              </div>
              <div>
                <span>10回：</span>
                <span className="mr-2 font-medium line-through">
                  {price10}円
                </span>
                <span className="text-red font-medium">
                  {calcDiscountedPrice(price10, initialDiscountRate)}円
                </span>
              </div>
            </>
          ) : (
            <>
              <span>1回：{price1}円</span>
              <br />
              <span>10連：{price10}円</span>
              {!isDuplicate && (
                <span className="text-red font-medium">10連で被りなし！</span>
              )}
            </>
          )}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">販売期間</td>
        <td>
          {formattedStart}
          <br />〜{formattedEnd}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">配送依頼期限</td>
        <td>
          {shippingRequestDeadline
            ? convertshippingRequestDeadline(shippingRequestDeadline)
            : "データが見つかりません。"}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">発送予定時期</td>
        <td>
          {scheduledShippingAt
            ? convertScheduledShippingAt(scheduledShippingAt)
            : "データが見つかりません。"}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">送料・梱包費（税込）</td>
        <td>
          配送依頼ごとに全国一律
          <br />
          {postage}円※個数無制限
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">決済方法</td>
        <td>
          {paymentMethods && paymentMethods.length > 0 ? (
            paymentMethods.length > 1 ? (
              <ul>
                {paymentMethods.map((val, index) => (
                  <li key={`payment-${index}`}>
                    <span>{val}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <span>{paymentMethods[0]}</span>
            )
          ) : (
            <ul>
              <li key="payment-1">
                <span>クレジットカード</span>
              </li>
              <li key="payment-2">
                <span>Apple Pay</span>
              </li>
              <li key="payment-3">
                <span>Google Pay</span>
              </li>
            </ul>
          )}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">コピーライト</td>
        <td>(C) {copyright}</td>
      </tr>
    </BaseTable>
  );
};
