import { ProductType } from "@gengakuji/common";
import { useState } from "react";

type SlideProps = {
  productData: ProductType | null;
  productId: string;
};

export const Slide = ({ productData, productId }: SlideProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);

  const assets: string[] = Object.values(productData?.productSlides || {}).map(
    (slide) => {
      const filename = slide.split("/").pop();
      return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/${filename}`;
    }
  );

  // スライドがない場合にはデフォルトスライドを追加！
  if (
    !productData ||
    !productData.productSlides ||
    Object.keys(productData.productSlides).length === 0
  ) {
    assets.push(
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/parapara.mp4`,
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/postcard.webp`,
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/digital.mp4`
    );
  }

  const handleNavigation = (direction: "left" | "right") => {
    setCurrentIndex((prev) => {
      if (direction === "left") {
        return prev === 0 ? assets.length - 1 : prev - 1;
      } else {
        return prev === assets.length - 1 ? 0 : prev + 1;
      }
    });
  };

  const isVideo = (asset: string) => {
    const url = new URL(asset);
    const pathname = url.pathname;
    return pathname.endsWith(".mp4");
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX !== null && touchEndX !== null) {
      const diff = touchStartX - touchEndX;
      if (diff > 50) {
        // 左から右へスワイプ ⇒ 次へ
        handleNavigation("right");
      } else if (diff < -50) {
        // 右から左へスワイプ ⇒ 前へ
        handleNavigation("left");
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  return (
    <>
      <div
        className="relative z-10 mx-auto w-full overflow-hidden"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {assets.map((asset, index) => (
          <div
            key={asset + index}
            className={`${
              currentIndex === index ? "block" : "hidden"
            } h-auto w-full`}
          >
            {isVideo(asset) ? (
              <video
                src={asset}
                autoPlay
                loop
                playsInline
                controls={false}
                muted={true}
                className="h-auto w-full"
              />
            ) : (
              <img
                src={asset}
                alt={`Slide ${index}`}
                className="h-auto w-full"
              />
            )}
          </div>
        ))}

        {/* インジケーター部分！ */}
        <div className="absolute bottom-3 left-1/2 z-20 flex -translate-x-1/2">
          {assets.map((_, index) => (
            <div
              key={index}
              className={`${
                currentIndex === index
                  ? "bg-gray opacity-90"
                  : "bg-gray opacity-30"
              } mx-1 size-3 rounded-full transition-colors`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>

        {/* ナビゲーションボタン部分！ */}
        <div
          className="absolute left-2 top-1/2 z-20 flex size-12 -translate-y-1/2 cursor-pointer select-none items-center justify-center rounded-full bg-black/50 text-center text-2xl leading-[3rem] text-white"
          onClick={() => handleNavigation("left")}
        >
          {"<"}
        </div>
        <div
          className="absolute right-2 top-1/2 z-20 flex size-12 -translate-y-1/2 cursor-pointer select-none items-center justify-center rounded-full bg-black/50 text-center text-2xl leading-[3rem] text-white"
          onClick={() => handleNavigation("right")}
        >
          {">"}
        </div>
      </div>
    </>
  );
};
