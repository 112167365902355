import { ProductType, CutInfoType } from "@gengakuji/common";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { BaseViewer } from "../components/BaseViewer";
import { BaseButton } from "../components/Button/BaseButton";
import { BlackButton } from "../components/Button/BlackButton";
import { CutModal } from "../components/Modal/CutModal";
import { Space } from "../components/Space";
import {
  getUserIdToken,
  getCutInfo,
  getProduct,
  getParapara,
} from "../firebase/index";

const Viewer = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState<string[] | null>(null);
  const [cutInfo, setCutInfo] = useState<CutInfoType | null>(null);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const { state } = useLocation();
  const productId = String(state.productId);
  const cutId = String(state.cutId);

  useEffect(() => {
    const fetchParapara = async () => {
      console.log(productId, cutId);
      if (productId == null || cutId == null) {
        console.log("productId == null || cutId == null");
        return;
      }
      const idToken = await getUserIdToken();
      if (idToken === null) {
        return;
      }

      try {
        const cutInfoData = await getCutInfo(productId, cutId);
        const productData = await getProduct(productId);
        if (cutInfoData && productData) {
          setCutInfo(cutInfoData);
          setProductData(productData);

          const paraparaImages = await getParapara(
            productId,
            cutId,
            cutInfoData.mixedCount,
          );
          setImages(paraparaImages);
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParapara();
  }, [productId, cutId]);

  return (
    <>
      <Helmet>
        <title>{`原画ビューア/${productId}/${cutInfo?.displayName}`}</title>
      </Helmet>
      <BaseViewer images={images} isLoading={isLoading} />
      <BlackButton title="原画情報を見る" onClick={() => setShowModal(true)} />
      <Space height={48} />
      <Link to={`/collection/${productId}`}>
        <BaseButton title="戻る" backgroundColor="#c9e900" textColor="#000" />
      </Link>
      <Space height={48} />
      {showModal && (
        <CutModal
          cutInfo={cutInfo}
          productData={productData}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
});

export default Viewer;
