import { createContext, useContext, useState } from "react";

interface ProductIdContextType {
  productId: string | null;
  setProductId: (productId: string | null) => void;
}

const ProductIdContext = createContext<ProductIdContextType | undefined>(
  undefined,
);

export const ProductIdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [productId, setProductId] = useState<string | null>(null);

  return (
    <ProductIdContext.Provider value={{ productId, setProductId }}>
      {children}
    </ProductIdContext.Provider>
  );
};

export const useProductId = () => {
  const context = useContext(ProductIdContext);
  if (context === undefined) {
    throw new Error("エラー");
  }
  return context;
};
