import { CollectionType } from "@gengakuji/common";

import { FirestoreDocument } from "../constants";

export const sortCollectionByIndex = (
  collections: FirestoreDocument<CollectionType>[],
): FirestoreDocument<CollectionType>[] => {
  return collections.sort((a, b) => {
    return a.cutInfo.index - b.cutInfo.index;
  });
};
