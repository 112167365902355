import { Timestamp } from "firebase/firestore";

import {
  convertDateToDays,
  convertFormattedDate,
  convertTimeStampToDate,
} from "./convertTimeStampToDate";

const today = new Date();

const isPastDate = (days: ReturnType<typeof convertDateToDays>) => {
  const dateFromDays = new Date(
    days.year,
    days.month - 1,
    days.day,
    days.hours,
    days.minutes,
  );
  return dateFromDays < today;
};

export const convertshippingRequestDeadline = (
  timestamp: Timestamp | Timestamp[],
): string | JSX.Element => {
  if (Array.isArray(timestamp) && timestamp.length >= 2) {
    const listItems = timestamp.map((t, index) => {
      const date = convertTimeStampToDate(t);
      const days = convertDateToDays(date);
      const shouldStrikeThrough = isPastDate(days);
      return (
        <p key={`deadline-${index}`}>
          <span
            style={{
              textDecoration: shouldStrikeThrough ? "line-through" : "none",
            }}
          >
            {index + 1}次：
            {convertFormattedDate(t)}
          </span>
          {shouldStrikeThrough && <span>（終了）</span>}
        </p>
      );
    });
    return <>{listItems}</>;
  } else if (Array.isArray(timestamp)) {
    return convertFormattedDate(timestamp[0]);
  } else {
    return convertFormattedDate(timestamp);
  }
};
