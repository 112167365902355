export const convertScheduledShippingAt = (
  dates: string[] | string,
): string | JSX.Element => {
  if (Array.isArray(dates) && dates.length >= 2) {
    const listItems = dates.map((date, index) => (
      <p key={`shipping-${index}`}>
        {index + 1}次：{date}
      </p>
    ));
    return <>{listItems}</>;
  } else if (Array.isArray(dates)) {
    return dates[0];
  } else {
    return dates;
  }
};
