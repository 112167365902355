import { memo } from "react";

import { Input } from "./Input";
import { Space } from "./Space";

interface Props {
  firstName: string;
  lastName: string;
  sei: string;
  mei: string;
  phone: string;
  zip: string;
  prefecture: string;
  city: string;
  address: string;
  building: string;

  firstNameError?: string;
  lastNameError?: string;
  seiError?: string;
  meiError?: string;
  phoneError?: string;
  zipError?: string;

  setFirstName?: (value: string) => void;
  setLastName?: (value: string) => void;
  setSei?: (value: string) => void;
  setMei?: (value: string) => void;
  setPhone?: (value: string) => void;
  setZip?: (value: string) => void;
  setPrefecture?: (value: string) => void;
  setCity?: (value: string) => void;
  setAddress?: (value: string) => void;
  setBuilding?: (value: string) => void;

  setFirstNameError?: (value: string) => void;
  setLastNameError?: (value: string) => void;
  setSeiError?: (value: string) => void;
  setMeiError?: (value: string) => void;
  setPhoneError?: (value: string) => void;
  setZipError?: (value: string) => void;

  isReadOnly?: boolean;
}

export const AddressForm = memo(
  ({
    firstName,
    lastName,
    sei,
    mei,
    phone,
    zip,
    address,
    building,
    prefecture,
    city,

    firstNameError = "",
    lastNameError = "",
    seiError = "",
    meiError = "",
    phoneError = "",
    zipError = "",

    setFirstName = () => {},
    setLastName = () => {},
    setSei = () => {},
    setMei = () => {},
    setPhone = () => {},
    setZip = () => {},
    setAddress = () => {},
    setBuilding = () => {},
    setPrefecture = () => {},
    setCity = () => {},

    setFirstNameError = () => {},
    setLastNameError = () => {},
    setSeiError = () => {},
    setMeiError = () => {},
    setPhoneError = () => {},
    setZipError = () => {},

    isReadOnly = false,
  }: Props) => {
    const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setZip(e.target.value);
      const zip = e.target.value;
      const hasOnlyNumbers = /^\d+$/.test(zip);

      if (hasOnlyNumbers && zip.length === 7) {
        fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.status === 200 && data.results) {
              setPrefecture(data.results[0].address1);
              setCity(data.results[0].address2 + data.results[0].address3);
              setZipError("");
            } else {
              setPrefecture("");
              setCity("");
              setZipError("郵便番号に対応する場所が見つかりません。");
            }
          })
          .catch((error) => console.error(error));
      } else {
        setZipError("数字のみ入力可・7文字");
      }
    };

    const validateName = (name: string) => {
      return name.match(
        /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/,
      )
        ? true
        : false;
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setFirstName(e.target.value);
      if (validateName(e.target.value)) {
        setFirstNameError("");
      } else {
        setFirstNameError("漢字・ひらがな・カタカナのみ入力可");
      }
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setLastName(e.target.value);
      if (validateName(e.target.value)) {
        setLastNameError("");
      } else {
        setLastNameError("漢字・ひらがな・カタカナのみ入力可");
      }
    };

    const handleSeiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setSei(e.target.value);
      if (!/^[\u30A0-\u30FF]+$/i.test(e.target.value)) {
        setSeiError("カタカナのみ入力可");
      } else {
        setSeiError("");
      }
    };

    const handleMeiChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setMei(e.target.value);
      if (!/^[\u30A0-\u30FF]+$/i.test(e.target.value)) {
        setMeiError("カタカナのみ入力可");
      } else {
        setMeiError("");
      }
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setPhone(e.target.value);
      const phone = e.target.value;
      if (!/^\d+$/.test(phone) || phone.length < 10 || phone.length > 11) {
        setPhoneError("数字のみ入力可・10文字または11文字");
      } else {
        setPhoneError("");
      }
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setAddress(e.target.value);
    };

    const handleBuildingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly) return;
      setBuilding(e.target.value);
    };

    return (
      <>
        <div className="text-left">
          <>
            <label>お名前</label>
            <div className="flex w-full justify-center">
              <div className="mr-1 flex-1">
                <Input
                  type="text"
                  placeholder="姓"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  errorMessage={firstNameError}
                  readOnly={isReadOnly}
                />
              </div>
              <div className="ml-1 flex-1">
                <Input
                  type="text"
                  placeholder="名"
                  value={lastName}
                  onChange={handleLastNameChange}
                  errorMessage={lastNameError}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </>
          <Space height={16} />
          <>
            <label>フリガナ</label>
            <div className="flex w-full justify-center">
              <div className="mr-1 flex-1">
                <Input
                  type="text"
                  placeholder="セイ"
                  value={sei}
                  onChange={handleSeiChange}
                  errorMessage={seiError}
                  readOnly={isReadOnly}
                />
              </div>
              <div className="ml-1 flex-1">
                <Input
                  type="text"
                  placeholder="メイ"
                  value={mei}
                  onChange={handleMeiChange}
                  errorMessage={meiError}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </>
          <Space height={16} />
          <>
            <label>
              電話番号<span className="text-xs">※ハイフン不要</span>
            </label>
            <Input
              type="tel"
              placeholder="09012345678"
              value={phone}
              onChange={handlePhoneChange}
              errorMessage={phoneError}
              maxLength={11}
              minLength={10}
              readOnly={isReadOnly}
            />
          </>
          <Space height={44} />
          <>
            <label>
              郵便番号<span className="text-xs">※ハイフン不要</span>
            </label>
            <Input
              type="tel"
              placeholder="1234567"
              value={zip}
              onChange={handleZipChange}
              errorMessage={zipError}
              maxLength={7}
              minLength={7}
              readOnly={isReadOnly}
            />
          </>
          <Space height={16} />
          <>
            <label>
              都道府県<span className="text-xs">※自動入力</span>
            </label>
            <Input type="text" placeholder="〇県" value={prefecture} readOnly />
          </>
          <Space height={16} />
          <>
            <label>
              市区町村<span className="text-xs">※自動入力</span>
            </label>
            <Input
              type="text"
              placeholder="△市◻︎区×町"
              value={city}
              readOnly
            />
          </>
          <Space height={16} />
          <>
            <label>番地</label>
            <Input
              type="text"
              placeholder="1-2-3"
              value={address}
              onChange={handleAddressChange}
              readOnly={isReadOnly}
            />
          </>
          <Space height={16} />
          <>
            <label>
              建物名・部屋番号<span className="text-xs">※任意</span>
            </label>
            <Input
              type="text"
              placeholder="☆ビル 123号室"
              value={building}
              onChange={handleBuildingChange}
              readOnly={isReadOnly}
            />
          </>
          <Space height={16} />
        </div>
      </>
    );
  },
);
