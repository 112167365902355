import { ProductType, SALE_STATUS, SaleStatus } from "@gengakuji/common";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

export const convertStatusToLabel = (status: SaleStatus): string => {
  switch (status) {
    case SALE_STATUS.BEFORE_SALE:
      return "販売決定";
    case SALE_STATUS.NOW_ON_SALE:
      return "販売中";
    case SALE_STATUS.AFTER_SALE:
      return "販売終了";
  }
};

export const convertProductStatus = (
  open: Timestamp,
  start: Timestamp,
  end: Timestamp,
): SaleStatus => {
  const now = Timestamp.now();
  if (open <= now && now < start) {
    return SALE_STATUS.BEFORE_SALE;
  } else if (start <= now && now <= end) {
    return SALE_STATUS.NOW_ON_SALE;
  } else {
    return SALE_STATUS.AFTER_SALE;
  }
};

export const getProduct: (
  productId: string,
) => Promise<ProductType | null> = async (productId: string) => {
  const productRef = doc(db, "products", productId);
  const productSnap = await getDoc(productRef);
  if (productSnap.exists()) {
    return productSnap.data() as ProductType;
  } else {
    return null;
  }
};

export const getProductList = async (): Promise<
  (ProductType & { saleStatus: SaleStatus })[]
> => {
  const now = Timestamp.now();
  const productsCollection = collection(db, "products");
  const q = query(
    productsCollection,
    where("open", "<=", now),
    orderBy("open", "desc"),
  );
  const querySnapshot = await getDocs(q);

  let productList: (ProductType & { saleStatus: SaleStatus })[] = [];

  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      let data = doc.data() as ProductType;
      let saleStatus = convertProductStatus(data.open, data.start, data.end);
      productList.push({ ...data, saleStatus });
    });

    productList.sort((a, b) => {
      // saleStatus に基づいてカスタムソート順を定義
      const order = { 1: 0, 0: 1, 2: 2 }; // 販売中、販売決定、販売終了の順
      const orderA = order[a.saleStatus];
      const orderB = order[b.saleStatus];

      if (orderA !== orderB) {
        return orderA - orderB;
      }

      return b.start.toDate().getTime() - a.start.toDate().getTime();
    });
  }
  return productList;
};
