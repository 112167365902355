import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { Border } from "../components/Border";
import { BaseButton } from "../components/Button/BaseButton";
import { DisabledButton } from "../components/Button/DisabledButton";
import { GoogleButton } from "../components/Button/GoogleButton";
import { ColorSpan } from "../components/ColorSpan";
import { H2 } from "../components/H2";
import { Input } from "../components/Input";
import { ResetModal } from "../components/Modal/ResetModal";
import { Popup } from "../components/Popup";
import { Space } from "../components/Space";
import { auth } from "../firebase";
import { useAuthUser } from "../hooks/useAuth";
import { handleEmailChange, handlePasswordChange } from "../utils";
const title = "ログイン";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get("from");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const user = useAuthUser();
  const isFormValid = !emailError && !passwordError && email && password;

  //登録済みのユーザーか判断
  useEffect(() => {
    if (
      ["contact", "shipping", "collection", "mypage"].includes(
        redirectPath ?? "",
      )
    ) {
      setShowPopup(true);
    }
    const fetchUser = async () => {
      if (user != null) {
        navigate(redirectPath ? `/${redirectPath}` : "/");
      }
    };
    fetchUser();
  }, [user, navigate]);

  // メールアドレスとパスワードでログイン
  const handleSubmit = async () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async () => {
        navigate(redirectPath ? `/${redirectPath}` : "/");
      })
      .catch((error) => {
        console.error(error);
        switch (error.code) {
          case "auth/user-not-found":
            setPasswordError("アカウントが存在しません。");
            break;
          case "auth/wrong-password":
            setPasswordError("パスワードが違います。");
            break;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Googleでログイン
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate(redirectPath ? `/${redirectPath}` : "/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text={title} blue={3} />
        <Input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => handleEmailChange(e, setEmail, setEmailError)}
          errorMessage={emailError}
        />
        <Space height={24} />
        <Input
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) =>
            handlePasswordChange(e, setPassword, setPasswordError)
          }
          errorMessage={passwordError}
        />
        <Space height={24} />
        {isFormValid ? (
          <BaseButton
            title="メールアドレスでログイン"
            backgroundColor="#ff4127"
            textColor="#fff"
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        ) : (
          <DisabledButton title="メールアドレスでログイン" />
        )}
        <Space height={24} />
        <div onClick={() => setshowModal(true)}>
          <span className="text-blue hover:border-blue hover:text-blue cursor-pointer text-sm font-medium hover:border-b">
            パスワードを変更する
          </span>
        </div>
        <Space height={48} />
        <Border color="#cacaca" />
        <Space height={48} />
        <GoogleButton title="Googleでログイン" onClick={signInWithGoogle} />
        <Space height={48} />
        <Link
          to={redirectPath ? `/register?from=${redirectPath}` : "/register"}
        >
          <ColorSpan>アカウントをお持ちでない方はこちら</ColorSpan>
        </Link>
        <Space height={48} />
        {showModal && <ResetModal onClose={() => setshowModal(false)} />}
      </BaseWhiteBg>
      <Popup
        isVisible={showPopup}
        onClose={() => setShowPopup(false)}
        message={
          "ご指定のページへアクセスするためにはログインが必要です。\nログインすると自動でそのページへ遷移します。"
        }
      />
    </>
  );
};

export default Login;
