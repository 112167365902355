import { ProductType } from "@gengakuji/common";

import { getUserIdToken } from "../firebase/auth";

export const buyKuji = async (
  drawNumber: number,
  firstBuy: boolean,
  productData: ProductType | null,
) => {
  if (!productData) return false;
  let priceId;
  if (drawNumber === 1) {
    if (firstBuy) {
      priceId = productData.priceIds.discountOne;
    } else {
      priceId = productData.priceIds.one;
    }
  } else {
    if (firstBuy) {
      priceId = productData.priceIds.discountTen;
    } else {
      priceId = productData.priceIds.ten;
    }
  }
  const idToken = await getUserIdToken();
  if (idToken === null) return;
  const body = {
    priceId,
    productId: productData.productId,
    drawNumber: drawNumber,
    firstBuy,
  };
  try {
    const res = await fetch(
      process.env.REACT_APP_CREATE_BUY_KUJI_CHECKOUT_SESSION,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
    const data = await res.json();
    const { redirectURL } = data;
    window.location.replace(redirectURL);
  } catch (e) {
    console.error(e);
    return;
  }
};
