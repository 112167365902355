import { RequestedShipInfo } from "@gengakuji/common";
import { useEffect, useState, memo } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";

import s_icon from "../assets/special/s_icon.webp";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { ColorSpan } from "../components/ColorSpan";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { useAuthUser } from "../hooks/useAuth";
import { convertFormattedDate, formatZipCode } from "../utils";

const ShippingDetail = memo(() => {
  const { customReceiptNumber } = useParams<{ customReceiptNumber: string }>();
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(true);
  const [requestedShipInfo, setRequestedShipInfo] =
    useState<RequestedShipInfo | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user && customReceiptNumber) {
        try {
          // Firebase ID トークンを取得
          const idToken = await user.getIdToken();

          const response = await fetch(
            process.env.REACT_APP_GET_SHIP_INFO_DETAIL!,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify({
                customReceiptNumber: customReceiptNumber,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("データの取得に失敗しました");
          }

          const data: RequestedShipInfo = await response.json();
          setRequestedShipInfo(data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user, customReceiptNumber]);

  if (isLoading) {
    return (
      <BaseWhiteBg>
        <Space height={64} />
        <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
        <Space height={64} />
      </BaseWhiteBg>
    );
  }

  if (!requestedShipInfo) {
    return (
      <BaseWhiteBg>
        <Space height={32} />
        <p>データが見つかりませんでした。</p>
        <Space height={16} />
        <Link to="/shipping">
          <ColorSpan>配送管理へ戻る</ColorSpan>
        </Link>
      </BaseWhiteBg>
    );
  }

  const { productData, shipInfo, address, items } = requestedShipInfo;
  const trackingUrl = `https://trackings.post.japanpost.jp/services/srv/search/direct?reqCodeNo1=${
    shipInfo.trackingNumber ? shipInfo.trackingNumber : ""
  }`;

  return (
    <>
      <Helmet>
        <title>配送情報詳細</title>
      </Helmet>
      <BaseWhiteBg>
        <Space height={32} />
        <Img imgPath={`${productData.productId}/ogp.png`} alt="商品画像" />
        <Space height={16} />
        <h3>{productData.productName}</h3>
        <Space height={8} />
        <p>全{shipInfo.numOfAvailableShip}点</p>
        <Space height={16} />
        <div className="text-left">
          <>
            {shipInfo.state === 1 ? (
              <>
                <label>配送依頼日時</label>
                <p>{convertFormattedDate(shipInfo.createdAt)}</p>
                <Space height={16} />
                <label>発送予定時期</label>
                <p>{shipInfo.FinalShippingDate}</p>
                <Space height={16} />
              </>
            ) : shipInfo.state === 3 ? (
              <>
                <label>発送日時</label>
                <p>
                  {shipInfo.shippedAt
                    ? convertFormattedDate(shipInfo.shippedAt)
                    : ""}
                </p>
                <Space height={16} />
                <label>配送業者</label>
                <p>日本郵便</p>
                <Space height={16} />
                <label>追跡番号</label>
                <p>{shipInfo.trackingNumber ? shipInfo.trackingNumber : ""}</p>
                <p className="text-xs text-[#999999]">
                  ※配送状況は
                  <a
                    href={trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue underline"
                  >
                    こちら
                  </a>
                  から確認可能です。
                </p>
                <Space height={16} />
              </>
            ) : null}
            <label>配送管理番号</label>
            <p>{shipInfo.customReceiptNumber}</p>
            <Space height={16} />
            <label>配送先</label>
            <p>
              {address.sei}
              {address.mei}
              <br />
              {address.firstName}
              {address.lastName}
            </p>
            <p>{address.phone}</p>
            <Space height={8} />
            <p>〒{formatZipCode(address.zip)}</p>
            <p>
              {address.prefecture}
              {address.city}
              {address.address}
              <br />
              {address.building}
            </p>
          </>
        </div>
        <Space height={16} />
        {/* アイテムリストの表示 */}
        {items && items.length > 0 && (
          <div className="mt-4 space-y-4">
            {items.map((item) => (
              <div
                key={item.cutId}
                className="flex items-center justify-between"
              >
                {/* 左側の画像ブロック */}
                <div className="relative inline-block w-1/2">
                  {item.type === "S" && (
                    <>
                      <img
                        src={s_icon}
                        alt="S賞アイコン"
                        className="absolute right-0 top-0 z-[101] w-1/4"
                      />
                    </>
                  )}
                  <Img
                    imgPath={`${productData.productId}/cover/${item.index}.png`}
                    alt={item.name}
                    className="w-full shadow-md"
                  />
                </div>

                {/* 右側の情報ブロック */}
                <div className="w-1/2 pl-4">
                  <p className="text-left text-base">{item.name}</p>
                  <p className="text-left text-base">数量: {item.amount}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <Space height={48} />
        <Link
          to={(() => {
            if (shipInfo.state === 1) return "/shipping#requested";
            if (shipInfo.state === 3) return "/shipping#shipped";
            return "/shipping"; // デフォルトのリンク先
          })()}
        >
          <ColorSpan>配送管理へ戻る</ColorSpan>
        </Link>
        <Space height={44} />
      </BaseWhiteBg>
    </>
  );
});

export default ShippingDetail;
